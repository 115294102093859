import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["carbon"]

  connect() {
    this.element.addEventListener("custom:update-carbon", this.updateCarbon.bind(this));
  }

  updateCarbon(event) {
    const oldValue = this.carbonTarget.textContent;
    const newValue = event.detail.value;

    this.carbonTarget.style.opacity = 1;
    this.carbonTarget.animate(
      [
        { opacity: 1 },
        { opacity: 0 }
      ],
      { duration: 400 }
    ).onfinish = () => {
      this.carbonTarget.textContent = newValue
      this.carbonTarget.animate(
        [
          { opacity: 0 },
          { opacity: 1 }
        ],
        { duration: 400 }
      )
    };
  }
}
